// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jobs-js": () => import("./../../../src/pages/jobs.js" /* webpackChunkName: "component---src-pages-jobs-js" */),
  "component---src-pages-leistungen-amalgamsanierung-js": () => import("./../../../src/pages/leistungen/amalgamsanierung.js" /* webpackChunkName: "component---src-pages-leistungen-amalgamsanierung-js" */),
  "component---src-pages-leistungen-aufbissschienen-js": () => import("./../../../src/pages/leistungen/aufbissschienen.js" /* webpackChunkName: "component---src-pages-leistungen-aufbissschienen-js" */),
  "component---src-pages-leistungen-js": () => import("./../../../src/pages/leistungen.js" /* webpackChunkName: "component---src-pages-leistungen-js" */),
  "component---src-pages-leistungen-keramikimplantate-js": () => import("./../../../src/pages/leistungen/keramikimplantate.js" /* webpackChunkName: "component---src-pages-leistungen-keramikimplantate-js" */),
  "component---src-pages-leistungen-keramikinlays-onlays-js": () => import("./../../../src/pages/leistungen/keramikinlays-onlays.js" /* webpackChunkName: "component---src-pages-leistungen-keramikinlays-onlays-js" */),
  "component---src-pages-leistungen-keramischer-zahnersatz-js": () => import("./../../../src/pages/leistungen/keramischer-zahnersatz.js" /* webpackChunkName: "component---src-pages-leistungen-keramischer-zahnersatz-js" */),
  "component---src-pages-leistungen-knochenaufbau-js": () => import("./../../../src/pages/leistungen/knochenaufbau.js" /* webpackChunkName: "component---src-pages-leistungen-knochenaufbau-js" */),
  "component---src-pages-leistungen-kunststofffuellungen-js": () => import("./../../../src/pages/leistungen/kunststofffuellungen.js" /* webpackChunkName: "component---src-pages-leistungen-kunststofffuellungen-js" */),
  "component---src-pages-leistungen-mundhygiene-js": () => import("./../../../src/pages/leistungen/mundhygiene.js" /* webpackChunkName: "component---src-pages-leistungen-mundhygiene-js" */),
  "component---src-pages-leistungen-parodontitistherapie-js": () => import("./../../../src/pages/leistungen/parodontitistherapie.js" /* webpackChunkName: "component---src-pages-leistungen-parodontitistherapie-js" */),
  "component---src-pages-leistungen-prf-methode-js": () => import("./../../../src/pages/leistungen/prf-methode.js" /* webpackChunkName: "component---src-pages-leistungen-prf-methode-js" */),
  "component---src-pages-leistungen-veneers-js": () => import("./../../../src/pages/leistungen/veneers.js" /* webpackChunkName: "component---src-pages-leistungen-veneers-js" */),
  "component---src-pages-leistungen-weisheitszahnentfernung-js": () => import("./../../../src/pages/leistungen/weisheitszahnentfernung.js" /* webpackChunkName: "component---src-pages-leistungen-weisheitszahnentfernung-js" */),
  "component---src-pages-leistungen-zahnentfernung-js": () => import("./../../../src/pages/leistungen/zahnentfernung.js" /* webpackChunkName: "component---src-pages-leistungen-zahnentfernung-js" */),
  "component---src-pages-praxis-js": () => import("./../../../src/pages/praxis.js" /* webpackChunkName: "component---src-pages-praxis-js" */)
}

